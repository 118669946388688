import React, { useEffect, useState } from "react";
import logo from "../../../img/logo-app.png";
import { Card, FormSelect, Row } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import Schema from "../Schema";
import { FaCrown } from "react-icons/fa";
import { GiLaurelCrown } from "react-icons/gi";
import { GiQueenCrown } from "react-icons/gi";
import "../css/vote.css";

export default function Board() {
  const [filteredData, setFilteredData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [selectedService, setSelectedService] = useState(undefined);
  const [queryShowVotes, { data }] = useLazyQuery(Schema.TotalsVoteEmployee, {
    fetchPolicy: "cache-and-network",
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    queryShowVotes({
      variables: {
        where: {
          isService: selectedService ? selectedService : undefined,
        },
      },
    });
  }, [queryShowVotes, selectedService]);

  useEffect(() => {
    if (data?.summaryPointOfStaff?.data) {
      const filtered = data.summaryPointOfStaff.data.filter((value) => {
        const isServiceMatch =
          selectedService || value.isService === selectedService;
        const isSearchMatch = value.fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        return isServiceMatch && isSearchMatch;
      });
      setFilteredData(filtered);
    }
  }, [searchValue, data]);

  return (
    <div style={{ backgroundColor: "#f0f2f2" }} className="vh-100">
      <div className="col-md-12 m-auto p-2">
        <div className="card vh-100" style={{ background: "#f0f2f2" }}>
          <div className="card-header">
            <center>
              <img
                src={logo}
                alt="logo"
                style={{ width: 250, marginRight: "auto" }}
              />
              <hr />
              <h2 style={{ textAlign: "center", marginLeft: "auto" }}>
                ລາຍຊື່ພະນັກງານ<span style={{ color: "red" }}>ດີເດັ່ນ</span>
                ປະຈຳປີ <span style={{ color: "red" }}>2023</span>
              </h2>
            </center>
          </div>
          <Row className="p-2">
            <Card
              style={{ backgroundColor: "#f0f2f2" }}
              className="custom-card vw-100 vh-100"
            >
              <FormSelect
                className="form-control"
                style={{ height: "40px", borderRadius: "10px" }}
                value={selectedService}
                onChange={(e) => {
                  setSelectedService(e.target.value || undefined);
                }}
              >
                <option value="">ທັງໝົດ</option>
                {[
                  { label: "ພະນັກງານສຳນັກງານໃຫຍ່", value: "COMPANY" },
                  { label: "ພະນັກງານສູນໂຊກໃຫຍ່", value: "CENTER_DC" },
                  { label: "ສູນຕ່າງແຂວງ", value: "CENTER_ALL" },
                  { label: "ສາຂາSAMEDAY", value: "SAMEDAY" },
                  { label: "ສາຂາNEXTDAY", value: "NEXTDAY" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormSelect>
              <form>
                <div
                  className="list-group list-group-borderless"
                  style={{ maxHeight: "800px", overflow: "scroll" }}
                >
                  {filteredData?.map((value, index) => (
                    <div
                      className="list-group-item d-flex align-items-center"
                      style={{
                        borderRadius: "15px",
                        margin: "2px",
                      }}
                    >
                      <label
                        className="mb-0 d-flex align-items-center justify-content-between w-100"
                        style={{
                          fontSize: "15px",
                          marginLeft: "10px",
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        <div key={index} className="leaderboard-item">
                          <span
                            style={{
                              color:
                                index + 1 === 1
                                  ? "gold"
                                  : index + 1 === 2
                                  ? "gray"
                                  : index + 1 === 3
                                  ? "#654321"
                                  : "black",
                              fontSize:
                                index + 1 === 1
                                  ? "20px"
                                  : index + 1 === 2
                                  ? "18px"
                                  : index + 1 === 3
                                  ? "15px"
                                  : "13px",
                            }}
                          >
                            {index + 1 === 1 && <GiLaurelCrown />}
                            {index + 1 === 2 && <GiQueenCrown />}
                            {index + 1 === 3 && <FaCrown />}
                            {index + 1 > 3 && index + 1}
                          </span>
                          <span className="name">
                            {value?.fullName} {value?.lastName}
                          </span>
                          <span className="points">
                            {value?.TotalPoint} ຄະແນນ
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </form>
            </Card>
          </Row>
        </div>
      </div>
    </div>
  );
}
