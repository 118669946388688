import { gql } from "@apollo/client";

export default class Schema {
  static EMPLOYEES = gql`
    query EmployeeStaffs(
      $where: EmployeeStaffWhereInput
      $skip: Int
      $limit: Int
    ) {
      employeeStaffs(where: $where, skip: $skip, limit: $limit) {
        total
        data {
          _id
          firstName
          lastName
          phoneNumber
          gender
          status
          branch {
            _id
            title
          }
          center {
            st_id
            centerName
          }
        }
      }
    }
  `;
  static MEMBER_USER = gql`
    query MemberUsers($skip: Int, $limit: Int, $where: MemberUserWhereInput) {
      memberUsers(skip: $skip, limit: $limit, where: $where) {
        total
        data {
          first_name
          last_name
          role
          branch_id
          id_user
        }
      }
    }
  `;
  static ANS_BRANCH = gql`
    query Branches($skip: Int, $limit: Int, $where: BranchWhereInput) {
      branches(skip: $skip, limit: $limit, where: $where) {
        total
        data {
          branch_name
          id_branch
        }
      }
    }
  `;
  static ANS_center = gql`
    query AnsCenters($where: AnsCenterWhereInput) {
      ansCenters(where: $where) {
        total
        data {
          st_id
          centerName
        }
      }
    }
  `;
  static createCommentStaff = gql`
    mutation CreateCommentStaff($data: CommentStaffInput!) {
      createCommentStaff(data: $data) {
        status
      }
    }
  `;
  static UpdateCommentStaff = gql`
    mutation Mutation($where: WhereByStaffId!, $data: CommentStaffInput!) {
      updateCommentStaff(where: $where, data: $data) {
        status
      }
    }
  `;
  static TotalsVoteEmployee = gql`
  query SummaryPointOfStaff($where: WhereInputFilter) {
    summaryPointOfStaff(where: $where) {
      total
      data {
        TotalPoint
        fullName
        lastName
      }
    }
  }
  `;
}
