import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../img/logo-app.png";
import { FaPhoneAlt } from "react-icons/fa";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { useMutation } from "@apollo/client";
import Schema from "../Schema";
import { useFormik } from "formik";
import { messageError, messageSuccess } from "../../../helper";

export default function CheckPhoneNumber() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [CheckNumber, setCheckNumber] = useState("");
  const navigator = useNavigate();
  const [createNumberStaff] = useMutation(Schema.createCommentStaff);
  const isService = queryParams.get("isService");
  const point = queryParams.get("point");
  const employeeID = queryParams.get("employeeID");
  const branch = queryParams.get("branch");
  const center = queryParams.get("center");
  // const centerArray = JSON.parse(center); 
  console.log("center",center)
  

  const { handleSubmit, resetForm } = useFormik({
    initialValues: {
      tell_branch_sameday: "",
      tell_branch_nextday: "",
      tell_center_all: "",
      tell_staff: "",
      isService: "",
      point: 0,
      employeeID: 0,
      center: 0,
    },
 onSubmit: async () => {
      try {
        let data;
        if (isService === "COMPANY") {
          data = {
            tell_staff: String(CheckNumber),
            isService: String(isService),
            point: parseInt(point),
            employeeID: parseInt(employeeID),
          
          };
        } else if (isService === "CENTER_ALL") {
          data = {
            tell_center_all: String(CheckNumber),
            isService: String(isService),
            point: parseInt(point),
            center: parseInt(center) ,
          };
        } else if (isService === "CENTER_DC") {
          data = {
            tell_center_dc: String(CheckNumber),
            isService: String(isService),
            point: parseInt(point),
            employeeID: parseInt(employeeID),
          };
        } else if (isService === "NEXTDAY") {
          data = {
            tell_branch_nextday: String(CheckNumber),
            isService: String(isService),
            point: parseInt(point),
            branch: parseInt(branch),
          };
        } else if (isService === "SAMEDAY") {
          data = {
            tell_branch_sameday: String(CheckNumber),
            isService: String(isService),
            point: parseInt(point),
            branch: parseInt(branch),
          };
        }
        console.log("Data:", data);
        await createNumberStaff({
          
          variables: {
            data: data,
          },
        });

        messageSuccess("ດຳເນີນງານສຳເລັດ");
        resetForm();
        setTimeout(() => {
          navigator("/voteEmployee");
        }, 1000);
      } catch (error) {
        console.error("ເກີດຂໍ້ຜິດພາດ:", error);
        messageError("ເບີໂທຂອງທ່ານສາມາດເຂົ້າໄດ້ຄັ້ງດຽວ !!!");
      }
    },
  });
  return (
    <div style={{ backgroundColor: "#f0f2f2" }} className="vh-100">
      <div className="col-md-12 m-auto p-2">
        <div className="card vh-100" style={{ background: "#f0f2f2" }}>
          <div className="card-header">
            <center>
              <img
                src={logo}
                alt="logo"
                style={{ width: 250, marginRight: "auto" }}
              />
              <hr />
              <h2 style={{ textAlign: "center", marginLeft: "auto" }}>
                ໂຫວດພະນັກງານດີເດັ່ນປະຈຳປີ{" "}
                <span style={{ color: "red" }}>2023</span>
              </h2>
            </center>
          </div>
          <Card
            className="form-control vh-100"
            style={{ display: "flex", alignItems: "center", padding: "10px" }}
          >
            <div style={{ fontSize: "35px", padding: "5px" }}>
              <FaPhoneAlt />
            </div>{" "}
            <h2>ກະລຸນາປ້ອນເບີໂທຂອງທ່ານ</h2>
            <form className="form-horizontal mt-3" onSubmit={handleSubmit}>
              <div>
                <i className="text-danger">*ກາລຸນາປ້ອນເບີໃຫ້ຄົບຖ້ວນ!!!</i>
                <OutlinedInput
                  className="customBox"
                  id="outlined-adornment-amount"
                  name="phoneNumber"
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  inputProps={{ maxLength: 8, fontFamily: "LAOS" }}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    const value = e.target.value;
                    if (value === "" || re.test(value)) {
                      setCheckNumber(value);
                      setButtonEnabled(value.length === 8);
                    }
                  }}
                  type="text"
                  startAdornment={
                    <InputAdornment position="start">+85620 :</InputAdornment>
                  }
                />
              </div>

              <button
                style={{ marginTop: "5px", fontSize: "20px" }}
                type="submit"
                class=" from-control btn btn-success w-100"
                disabled={!isButtonEnabled}
              >
                ຢືນຢັນ
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
}
