import React, { useState } from "react";
import logo from "../../img/logo-app.png";
import { messageSuccess, messageWarning, valiDate } from "../../helper";
import { useFormik } from "formik";
import { InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import SelectBranch from "./SelectBranch";

export default function Home() {
  const [selectedBranch, setSelectedBranch] = useState();
  const [create_feedback] = useMutation(Schema.CREATE_FEEDBACK);
  const [phoneNumber, setPhoneNumber] = useState("");

  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      branch: "",
      phoneNumber: "",
      description: "",
      point: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors = {};
      if (!selectedBranch?.id_branch) errors.branch = "ກະລຸນາເລືອກສາຂາ";
      if (!phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
      if (!values.point) errors.point = "ກະລຸນາໃຫ້ຄະແນນ";
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await create_feedback({
          variables: {
            data: {
              branch: parseInt(selectedBranch?.id_branch),
              phoneNumber: parseInt(phoneNumber),
              description: values.description,
              point: parseInt(values.point),
            },
          },
        });
        messageSuccess("ດຳເນີນງານສຳເລັດ");
        resetForm();
        setPhoneNumber("");
        setPhoneNumber("");
      } catch (error) {
        messageWarning("ທ່ານໃຫ້ຄະແນນໄດ້ ຄັ້ງດຽວເທົ່ານັ້ນ");
      }
    },
  });
  return (
    <div style={{ backgroundColor: "#f0f2f2" }} className="vh-100">
      <div className="col-md-6 m-auto p-2">
        <div className="card">
          <div className="card-header">
            <img src={logo} alt="logo" style={{ width: 150 }} />
          </div>
          <div className="p-1">
            <center>
              <h2>ໂຫວດແຟຣນໄຊສ໌ດີເດັ່ນປະຈຳປີ 2023</h2>
            </center>
            <form className="form-horizontal mt-3" onSubmit={handleSubmit}>
              <div className="form-group">
                <SelectBranch
                  onChange={(obj) => {
                    setSelectedBranch(obj);
                  }}
                />
                <i className="text-danger">{errors.branch}</i>
              </div>
              <div className="mt-2">
                <OutlinedInput
                  className="customBox"
                  id="outlined-adornment-amount"
                  name="phoneNumber"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  error={errors.phoneNumber}
                  inputProps={{ maxLength: 9, fontFamily: "LAOS" }}
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    const value = e.target.value;
                    if (value === "" || re.test(value)) {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                  startAdornment={
                    <InputAdornment position="start">+85620 :</InputAdornment>
                  }
                />
                <i className="text-danger">{errors.phoneNumber}</i>
              </div>
              <div className={"form-group mt-2"}>
                <label className="control-label" style={{ color: "black" }}>
                  ຄະແນນຄວາມຄິດເຫັນຂອງລູກຄ້າ {valiDate()}
                </label>
                <div className="radio-group" style={{ color: "black" }}>
                  <input
                    type="radio"
                    id="option1"
                    name="point"
                    value={1}
                    onChange={handleChange}
                  />{" "}
                  <label htmlFor="option1">
                    1 ຄະແນນ <span>(ບໍລິການບໍ່ດີ )</span>
                  </label>
                </div>
                <div className="radio-group" style={{ color: "black" }}>
                  <input
                    type="radio"
                    id="option2"
                    name="point"
                    value={2}
                    onChange={handleChange}
                  />{" "}
                  <label htmlFor="option2">
                    2 ຄະແນນ <span>( ຕ້ອງປັບປຸງ )</span>
                  </label>
                </div>
                <div className="radio-group" style={{ color: "black" }}>
                  <input
                    type="radio"
                    id="option3"
                    name="point"
                    value={3}
                    onChange={handleChange}
                  />{" "}
                  <label htmlFor="option3">
                    3 ຄະແນນ <span>( ບໍລິການພໍໃຊ້ )</span>
                  </label>
                </div>
                <div className="radio-group" style={{ color: "black" }}>
                  <input
                    type="radio"
                    id="option1"
                    name="point"
                    value={4}
                    onChange={handleChange}
                  />{" "}
                  <label htmlFor="option4">
                    4 ຄະແນນ <span>( ບໍລິການດີ )</span>
                  </label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="option5"
                    name="point"
                    value={5}
                    onChange={handleChange}
                  />{" "}
                  <label htmlFor="option5" style={{ color: "black" }}>
                    5 ຄະແນນ <span>( ບໍລິການດີຫຼາຍ )</span>
                  </label>
                </div>
                <i className="text-danger">{errors.point}</i>
              </div>
              <div className="form-group">
                <label className="control-label" style={{ color: "black" }}>
                  ຄຳຄິດເຫັນເພີ່ມເຕີມ
                </label>
                <textarea
                  className="form-control"
                  style={{
                    borderRadius: "5px",
                  }}
                  rows="3"
                  placeholder="ເນື້ອໃນລາຍລະອຽດ"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                ></textarea>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-success form-control form-control-lg"
                >
                  <b style={{ fontSize: "15px" }}> ຢືນຢັນສົ່ງ</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
