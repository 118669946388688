import Notiflix, { Confirm } from "notiflix";
import React from "react";
import loadingImage from "../img/loading.gif";

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};
export const startLoading = () => {
  return Notiflix.Loading.standard("Loading...");
};
export const stopLoading = () => {
  return Notiflix.Loading.remove();
};

export const messageSuccess = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.success(e);
};

export const messageError = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.failure(e);
};

export const messageWarning = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.warning(e);
};
export const startSpriner = (color) => {
  return (
    <div className={`spinner-border text-${color}`} role="status">
      <span className="sr-only">ກຳລັງໂຫຼດຂໍ້ມູນ...</span>
    </div>
  );
};

export const sprinerLoading = (color, content) => {
  return (
    <span>
      <span
        style={{ position: "relative", marginBottom: "5%" }}
        className={`text-${color} spinner-border spinner-grow-sm mr-2`}
      ></span>
      <span style={{ position: "absolute", verticalAlign: "middle" }}>
        {content}
      </span>
    </span>
  );
};

export const loadingData = (size, content) => {
  return (
    <>
      <img
        src={loadingImage}
        alt="loading"
        style={{ width: size, height: size }}
      />{" "}
      {content}
    </>
  );
};

export const confirmShow = ({ title, action }) => {
  Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async () => {
      action();
    },
    () => {
      return false;
    }
  );
};


export const onConfirm = () => {
  Notiflix.Loading.pulse("Loading...");

  setTimeout(() => {
    confirmShow({
      title: "ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?",

      action: () => {
        window.location.replace("/voteEmployee");
      },
    });

    Notiflix.Loading.remove();
  }, 500);
};


export const RootVariable = {
  limit: 500,
};