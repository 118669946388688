import React from "react";
import logo from "../../img/logo-app.png";
import { FaHotel } from "react-icons/fa6";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom/dist";
import { messageWarning } from "../../helper";
import { FaClipboardCheck } from "react-icons/fa6";

export default function VoteEmployee() {
  const navigator = useNavigate();
  const handleClick = (route) => {
    if (route === "/board") {
      navigator(route);
    } else {
      const currentTime = new Date();
      const day = currentTime.getDay();
      const isPastFourPM = day >= 0;
      if (isPastFourPM) {
        messageWarning("ໝົດເວລາສຳລັບການໂຫວດແລ້ວ !!!");
      } else {
        navigator(route);
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#f0f2f2" }} className="vh-100">
      <div className="col-md-12 m-auto p-2">
        <div className="card vh-100" style={{ background: "#f0f2f2" }}>
          <div className="card-header">
            <center>
              <img
                src={logo}
                alt="logo"
                style={{ width: 210, marginRight: "auto" }}
              />
              <hr />
              <h2 style={{ textAlign: "center", marginLeft: "auto" }}>
                ໂຫວດພະນັກງານດີເດັ່ນປະຈຳປີ{" "}
                <span style={{ color: "red" }}>2023</span>
              </h2>
              <button
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                onClick={() => handleClick("/board")}
              >
                <FaClipboardCheck style={{ marginRight: "5px" }} />
                ເບິ່ງຄະແນນຂອງທ່ານ
              </button>
            </center>
          </div>
          <Row className="p-2">
            <Col xs={12} md={4} className="p-1">
              <Card
                onClick={() => handleClick("/HeaderOffice")}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <FaHotel style={{ fontSize: "54px" }} />
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "20px",
                    }}
                  >
                    ສຳນັກງານໃຫຍ່
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={4} className="p-1">
              <Card
                onClick={() => handleClick("/HeadOnCenter1")}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <FaHotel style={{ fontSize: "54px" }} />
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "20px",
                    }}
                  >
                    ສູນໂຊກໃຫຍ່
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={4} className="p-1">
              <Card
              
                onClick={() => handleClick("/HeadOnCenter2")}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <FaHotel style={{ fontSize: "54px" }} />
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "20px",
                    }}
                  >
                    ສູນຕ່າງແຂວງ
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={4} className="p-1">
              <Card onClick={() => handleClick("/BranchOnNextDay")}>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <FaHotel style={{ fontSize: "54px" }} />
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "20px",
                    }}
                  >
                    ສາຂາປະເພດ NextDay
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={4} className="p-1">
              <Card onClick={() => handleClick("/BranchOnSameDay")}>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <FaHotel style={{ fontSize: "54px" }} />
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "20px",
                    }}
                  >
                    ສາຂາປະເພດ SameDay
                  </span>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
