import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Routes from "./routes";
const AppContext = React.createContext();

export default function App() {
  const client = new ApolloClient({
       uri: "https://dash.api.anousith-express.com/graphql",
      // uri: "http://localhost:8800/graphql",
    cache: new InMemoryCache({
      addTypename: false,
    }),
    onError: (err) => {
      console.log("ERROR: ", err);
    },
  });
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
}

export { AppContext };
