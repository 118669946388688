import { gql } from "@apollo/client";

export default class Schema {
  static SELECT_BRANCH = gql`
  query Data($skip: Int, $limit: Int, $where: BranchWhereInput) {
    branches(skip: $skip, limit: $limit, where: $where) {
      data {
        isDeleted
        id_branch
        branch_name
      }
    }
  }
  `;
  static CREATE_FEEDBACK = gql`
    mutation CreateCommentBranchSchema($data: CommentBranchSchemaInput!) {
      createCommentBranchSchema(data: $data) {
        status
      }
    }
  `;
}
