import React from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Home from "../pages/feedback";
import VoteEmployees from "../pages/vote";
import HeaderOffice from "../pages/vote/component/HeaderOffice";
import BranchOnNextDay from "../pages/vote/component/BranchOnNextDay";
import BranchOnSameDay from "../pages/vote/component/BranchOnSameDay";
import CheckPhoneNumber from "../pages/vote/component/CheckPhoneNumber";
import HeadOnCenter1 from "../pages/vote/component/HeadOnCenter1";
import HeadOnCenter2 from "../pages/vote/component/HeadOnCenter2";
import Board from "../pages/vote/component/Board";



const publicRoute = createBrowserRouter([
  { path: "/", element: <Navigate to={'/voteEmployee'} /> },
  { path: '/home', element: <Home /> },
  { path: '/BranchOnNextDay', element: <BranchOnNextDay /> },
  { path: '/BranchOnSameDay', element: <BranchOnSameDay /> },
  { path: '/HeadOnCenter1', element: <HeadOnCenter1 /> },
  { path: '/HeadOnCenter2', element: <HeadOnCenter2 /> },
  { path: '/HeaderOffice', element: <HeaderOffice /> },
  { path: '/voteEmployee', element: <VoteEmployees /> },
  { path: '/CheckPhoneNumber', element: <CheckPhoneNumber /> },
  { path: '/board' , element: <Board /> },
]);

export default function RoutesApp() {
  return (
    <>
      <RouterProvider router={publicRoute} />
    </>
  );
}
