import React, { useEffect, useState } from "react";
import { messageSuccess, messageWarning } from "../../../helper";
import { Card, Row } from "react-bootstrap";
import logo from "../../../img/logo-app.png";
import Schema from "../Schema";
import { useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

export default function HeadOnCenter2() {
  const navigator = useNavigate();
  const [selectedEmpCenter2, setSelectedEmpCenter2] = useState([]);
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [headCenter2, setHeadCenter2] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [queryCenter, { data }] = useLazyQuery(Schema.ANS_center, {
    fetchPolicy: "cache-and-network",
  });
  console.log("selectedEmpCenter2", selectedEmpCenter2);
  const { handleSubmit, resetForm } = useFormik({
    initialValues: {
      isService: "CENTER_ALL",
      point: 1,
      center: selectedEmpCenter2.st_id || 0,
    },
    onSubmit: async (values) => {
      try {
        resetForm();
        setButtonEnabled(false);
        navigator(
          `/CheckPhoneNumber?isService=${values?.isService}&point=${
            values?.point
          }&center=${selectedEmpCenter2.st_id || 0}`
        );
      } catch (error) {
        console.error("ເກີດຂໍ້ຜິດພາດ:", error);
        messageWarning("ໂຫວດບໍ່ສຳເລັດ !!!");
      }
    },
  });

  useEffect(() => {
    if (data?.ansCenters?.data) {
      const filtered = data?.ansCenters?.data.filter((value) => {
        const isSearchMatch = value.centerName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        return isSearchMatch;
      });
      setHeadCenter2(searchValue ? filtered : data?.ansCenters?.data);
    }
  }, [searchValue, data]);

  useEffect(() => {
    queryCenter({
      variables: {
        data: {
          where: {
            center: 3,
            isPublic: 1,
          },
        },
      },
    });
  }, [queryCenter, searchValue]);


  useEffect(() => {
    setHeadCenter2(data?.ansCenters?.data || []);
  }, [data]);
  const handleRadioChange = (value) => {
    setSelectedEmpCenter2(value);
    setButtonEnabled(true);
  };
  return (
    <div style={{ backgroundColor: "#f0f2f2" }} className="vh-100">
      <div className="col-md-12 m-auto p-2">
        <div className="card vh-100" style={{ background: "#f0f2f2" }}>
          <div className="card-header">
            <center>
              <img
                src={logo}
                alt="logo"
                style={{ width: 250, marginRight: "auto" }}
              />
              <hr />
              <h2 style={{ textAlign: "center", marginLeft: "auto" }}>
                ລາຍຊື່<span style={{ color: "red" }}>ສູນຕ່າງແຂວງ</span>
                ປະຈຳປີ <span style={{ color: "red" }}>2023</span>
              </h2>
            </center>
          </div>
          <Row className="p-2">
            <Card
              style={{ backgroundColor: "#f0f2f2" }}
              className="custom-card vw-100 vh-100"
            >
              <input
                type="search"
                className="form-control"
                placeholder="ຄົ້ນຫາຊື່..."
                style={{ marginRight: "auto", marginLeft: "auto" }}
                onChange={(e) => {
                  setSearchValue(e.target.value || "");
                }}
              />
              <form onSubmit={handleSubmit}>
                <button
                  style={{ marginTop: "5px", fontSize: "20px" }}
                  type="submit"
                  class=" from-control btn btn-success w-100"
                  // onClick={onConfirm}
                  disabled={!isButtonEnabled}
                >
                  ຍືນຍັນ
                </button>
                <hr />

                <div
                  className="list-group list-group-borderless"
                  style={{ maxHeight: "700px", overflow: "scroll" }}
                >
                  {headCenter2?.map((value, index) => (
                    <div
                      className="list-group-item d-flex align-items-center"
                      style={{
                        borderRadius: "15px",
                        margin: "2px",
                        cursor: "pointer",
                      }}
                      key={index}
                    >
                      <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        className="me-2"
                        style={{ display: "none" }}
                        // checked={selectedEmpCenter2.some(
                        //   (emp) => emp.st_id === value.st_id
                        // )}
                        // onChange={() => handleRadioChange(value)}
                        checked={selectedEmpCenter2 === value}
                        onChange={() => handleRadioChange(value)}
                      />
                      <label
                        htmlFor={`checkbox${index}`}
                        className="mb-0 d-flex align-items-center justify-content-between w-100"
                        style={{
                          fontSize: "25px",
                          marginLeft: "20px",
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          <span>{index + 1}</span>{" "}
                          <span>{value?.centerName}</span>
                        </div>
                        {/* {selectedEmpCenter2.some(
                          (emp) => emp.st_id === value.st_id
                        ) && (
                          <FaHeart className="icon" style={{ color: "red" }} />
                        )}
                        {!selectedEmpCenter2.some(
                          (emp) => emp.st_id === value.st_id
                        ) && (
                          <FaHeart className="icon" style={{ color: "grey" }} />
                        )} */}
                        {selectedEmpCenter2 === value && (
                          <FaHeart className="icon" style={{ color: "red" }} />
                        )}
                        {selectedEmpCenter2 !== value && (
                          <FaHeart className="icon" style={{ color: "grey" }} />
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </form>
            </Card>
          </Row>
        </div>
      </div>
    </div>
  );
}
