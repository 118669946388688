import React, { useEffect, useState } from "react";
import Schema from "../Schema";
import { useLazyQuery } from "@apollo/client";
import logo from "../../../img/logo-app.png";
import { Card, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { messageWarning } from "../../../helper";
import { useNavigate } from "react-router-dom";

import { FaHeart } from "react-icons/fa";

function HeaderOffice() {
  const navigator = useNavigate();
  const [selectedEmpOffice, setSelectedEmpOffice] = useState(null);
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [empHeadOffice, setEmpHeadOffice] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [queryHeadOffice, { data: DataEmp }] = useLazyQuery(Schema.EMPLOYEES, {
    fetchPolicy: "cache-and-network",
  });

  ///// queryEmployee
  useEffect(() => {
    queryHeadOffice({
      variables: {
        where: {
          isFuntion: 1,
          branch: 2,
          firstName: searchValue ? searchValue : undefined,
          status: "ACTIVE",
        },
        limit: 500,
      },
    });
  }, [queryHeadOffice, searchValue]);

  useEffect(() => {
    setEmpHeadOffice(DataEmp?.employeeStaffs?.data || []);
  }, [DataEmp]);

  const handleCheckboxChange = (index, value) => {
    setSelectedEmpOffice(index, value);
    setButtonEnabled(true);
  };

  const { handleSubmit, resetForm } = useFormik({
    initialValues: {
      isService: "COMPANY",
      point: 1,
      employeeID: selectedEmpOffice?._id || 0,
    },
    onSubmit: async (values) => {
      try {
        resetForm();
        setButtonEnabled(false);
        navigator(
          `/CheckPhoneNumber?isService=${values?.isService}&point=${values?.point}&employeeID=${selectedEmpOffice?._id}`
        );
      } catch (error) {
        messageWarning("ໂຫວດບໍ່ສຳເລັດ !!!");
      }
    },
  });

  return (
    <div style={{ backgroundColor: "#f0f2f2" }} className="vh-100">
      <div className="col-md-12 m-auto p-2">
        <div className="card vh-100" style={{ background: "#f0f2f2" }}>
          <div className="card-header">
            <center>
              <img
                src={logo}
                alt="logo"
                style={{ width: 250, marginRight: "auto" }}
              />
              <hr />
              <h2 style={{ textAlign: "center", marginLeft: "auto" }}>
                ລາຍຊື່ພະນັກງານ<span style={{ color: "red" }}>ສຳນັກງານໃຫຍ່</span>
                ປະຈຳປີ <span style={{ color: "red" }}>2023</span>
              </h2>
            </center>
          </div>
          <Row className="p-2">
            <Card
              style={{ backgroundColor: "#f0f2f2" }}
              className="custom-card vw-100 vh-100"
            >
              <input
                type="search"
                className="form-control"
                placeholder="ຄົ້ນຫາຊື່..."
                style={{ marginRight: "auto", marginLeft: "auto" }}
                onChange={(e) => {
                  setSearchValue(e.target.value || "");
                }}
              />

              <form onSubmit={handleSubmit}>
                <button
                  style={{ marginTop: "5px", fontSize: "20px" }}
                  type="submit"
                  class=" from-control btn btn-success w-100"
                  disabled={!isButtonEnabled}
                >
                  ຢືນຢັນ
                </button>
                <hr />
                <div
                  className="list-group list-group-borderless"
                  style={{ maxHeight: "800px", overflow: "scroll" }}
                >
                  {empHeadOffice?.map((value, index) => (
                    <div
                      className="list-group-item d-flex align-items-center"
                      style={{
                        borderRadius: "15px",
                        margin: "2px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        className="me-2"
                        name="radiogroup"
                        style={{ display: "none" }}
                        checked={selectedEmpOffice === value}
                        onChange={() => handleCheckboxChange(value, index)}
                      />
                      <label
                        htmlFor={`checkbox${index}`}
                        className="mb-0 d-flex align-items-center justify-content-between w-100"
                        style={{
                          fontSize: "25px",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          <span>{index + 1}</span>{" "}
                          <span>
                            {value?.gender === "MALE" ? "ທ້າວ" : "ນາງ"}
                          </span>{" "}
                          <span>
                            {value?.firstName} {value?.lastName}
                          </span>
                        </div>
                        {selectedEmpOffice === value && (
                          <FaHeart className="icon" style={{ color: "red" }} />
                        )}
                        {selectedEmpOffice !== value && (
                          <FaHeart className="icon" style={{ color: "grey" }} />
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </form>
            </Card>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default HeaderOffice;
